import { Photo } from "../types/Photo";
import { storage } from '../libs/firebase';
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { v4 as createId } from "uuid";

export const getAll = async () => {
  let list: Photo[] = [];

  const imagesFolder = ref(storage, "images");// referencia a pasta images
  const photoList = await listAll(imagesFolder);// Pega lista de fotos

  for (let i in photoList.items) {
    let photoUrl = await getDownloadURL(photoList.items[i]);//Gera URL

    list.push({
      name: photoList.items[i].name,
      url: photoUrl
    });
  }

  return list;
}

export const insert = async (file: File) => {
  if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {

    let randomName = createId();
    let newFile = ref(storage, `images/${randomName}`);

    let upload = await uploadBytes(newFile, file);
    let photoUrl = await getDownloadURL(upload.ref);

    return { name: upload.ref.name, url: photoUrl }

  } else {
    return new Error('Tipo de arquivo não permitido!');
  }
}

export const deleteImg = async (imgName: string) => {
  
  if (imgName) {
      const imgRef = ref(storage, `images/${imgName}`);
      await deleteObject(imgRef);
  } else {
      console.log('Erro no services>photos>deleteImg: ');
  }
  // Comentario
}