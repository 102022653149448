import styled from "styled-components";

export const Container = styled.div`
  background-color: #3D3F43;
  border-radius: 10px;
  padding: 10px;

  img {
    max-width: 100%;
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .delete {
    display: flex;
    justify-content: flex-end;

    .deleteImage {
      background-color: #FFF;
      color: #ff1800;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: 5px;
      font-weight: bold;
      border: 0;

      &:hover {
        background-color: #ff1800;
        color: #FFF;
      }
    }  
  }
`;
