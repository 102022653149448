import { deleteObject, getStorage, ref } from 'firebase/storage';
import { storage } from '../../libs/firebase';
import * as C from './styles';

type Props = {
  url: string;
  name: string;
}

export const PhotoItem = ({ url, name }: Props) => {
  const imageRef = ref(storage,`images/${name}`);

  const onClickDelete= async ()=>{
    if (window.confirm("Tem certeza que deseja DELETAR este item?")) {
      const storage = getStorage();
      const storageRef = ref(storage, `images/${imageRef.name}`);    
      await deleteObject(storageRef);
      window.location.reload();
    }        
  }

  return (
    <C.Container>      
      <img src={url} alt={name} />
        {name}<br/>
        <div className='delete'>          
          <button 
          className="deleteImage" 
          onClick={onClickDelete}
          >
            Excluir
          </button>
        </div>  
                
    </C.Container>
  );
}