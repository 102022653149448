import styled from "styled-components";

export const Container = styled.div`
  background-color: #27282F;
  color: #FFF;
  min-height: 100vh;

  @media (max-width:600px) {
   padding: 0 10px;
  }
`;

export const Area = styled.div`
  margin: auto;
  max-width: 980px;
  padding: 30px 0px;
`;

export const Header = styled.h1`
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width:600px) {
    font-size: x-large;
  }
`;

export const ScreenWarning = styled.div`
  text-align: center;

  .emoji {
    font-size: 50px;
    margin-bottom: 20px;
  }
`;

export const PhotoList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width:600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const UploadForm = styled.form`
  background-color: #3D3F43;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width:600px) {
    flex-direction: column;
  }

  input[type=submit] {
    background-color: #756DF4;
    border: 0;
    color: #FFF;
    padding: 8px 16px;
    font-size: 15px;
    border-radius: 5px;
    margin: 0 20px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    @media (max-width:600px) {
      margin-top: 20px;
      margin-right: -187px;
    }
  }
`;